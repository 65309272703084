import { createStore } from 'vuex'
import app from './modules/app'
import getters from './getters'
import config from './modules/config'
import user from './modules/user'

const store = createStore({
  modules: {
    app,
    user,
    config
  },
  getters
})
export default store
