const defaultEnv = {
  SYSTEM_DOMAIN: 'http://eais.test.toris.vpn'
}

const configMap = {
  'portal.eais.test.toris.vpn': {
    SYSTEM_DOMAIN: 'http://eais.test.toris.vpn'
  },
  'portal.eais-ktz-test.adc.spb.ru': {
    SYSTEM_DOMAIN: 'http://system.eais-ktz-test.adc.spb.ru'
  },
  'portal.eais.toris.vpn': {
    SYSTEM_DOMAIN: 'http://eais.toris.vpn'
  },
  'portal.eais-ktz.adc.spb.ru': {
    SYSTEM_DOMAIN: 'http://system.eais-ktz.adc.spb.ru'
  },
  'portal.eais-ktz-pred.adc.spb.ru': {
    SYSTEM_DOMAIN: 'http://system.eais-ktz-pred.adc.spb.ru'
  }
}

const resolveEnv = () => {
  for (const [key, value] of Object.entries(configMap)) {
    if (key === window.location.hostname) {
      return value
    }
  }

  return {}
}

export default {
  state: Object.assign(defaultEnv, resolveEnv())
}
