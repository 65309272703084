<template>
  <div v-if="!hamburgerHidden" class="navbar">
    <hamburger
      :toggle-click="toggleSideBar"
      :is-active="sidebar.opened"
      class="hamburger-container" />
    <breadcrumb class="breadcrumb-container" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  name: 'NavbarComponent',
  components: {
    Hamburger,
    Breadcrumb
  },
  props: {
    hamburgerHidden: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'device'
    ])
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.nav {
  margin-left: 20px;
  font-size: 14px;
}

.s-i {
  float: right;
  font-size: 14px;
  color: grey;
  margin-right: 10px;
}

.navbar {
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  border-radius: 0 !important;
  position: sticky;
  top: 0;
  width: 99%;
  z-index: 1999;
  background: white;
  border-bottom: 1px solid #ebeef5;

  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }

    .international {
      vertical-align: top;
    }
  }
}
</style>
