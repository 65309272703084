<template>
  <div
    :class="['menu-container', item.meta.menuContainerClasses]">
    <span v-html="item.meta.title" />
    <template v-if="item.meta.iconName">
      <el-icon><component :is="item.meta.iconName" /></el-icon>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ItemTemplate',
  props: {
    item: { type: Object, required: true }
  }
}
</script>

<style scoped>
</style>
