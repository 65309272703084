<template>
  <div class="logo">
    <router-link to="/news">
      <img src="" />
      <div>Портал КТЗ</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'LogoBar'
}
</script>

<style scoped>
.logo {
  display: block;
  height: 50px;
  font-size: 20px;
  text-align: center;
  color: white;
}
</style>
