import { hasPermission } from '@/store/modules/permission'

export const ANYTHING = 'anything'

// Карточка новости
export const NEWS_FULL_ACCESS = 'news_full_access'
export const NEWS_READ = 'news_read'
export const NEWS_CREATE = 'news_create'
export const NEWS_EDIT = 'news_edit'

// Карточка НПА
export const NPA_FULL_ACCESS = 'npa_full_access'
export const NPA_READ = 'npa_read'
export const NPA_CREATE = 'npa_create'
export const NPA_EDIT = 'npa_edit'

// Карточка Планов
export const PLAN_FULL_ACCESS = 'plan_full_access'
export const PLAN_READ = 'plan_read'
export const PLAN_CREATE = 'plan_create'
export const PLAN_EDIT = 'plan_edit'

// Карточка Открытых данных
export const OPEN_DATA_FULL_ACCESS = 'open_data_full_access'
export const OPEN_DATA_READ = 'open_data_read'
export const OPEN_DATA_CREATE = 'open_data_create'
export const OPEN_DATA_EDIT = 'open_data_edit'

// Карточка Вопрос-Ответ
export const FAQ_FULL_ACCESS = 'faq_full_access'
export const FAQ_READ = 'faq_read'
export const FAQ_CREATE = 'faq_create'
export const FAQ_EDIT = 'faq_edit'

// Карточка Запрос по КНМ
export const KNM_REQUEST_FULL_ACCESS = 'knm_request_full_access'
export const KNM_REQUEST_READ = 'knm_request_read'
export const KNM_REQUEST_CREATE = 'knm_request_create'
export const KNM_REQUEST_EDIT = 'knm_request_edit'

// Уведомления
export const NOTIFICATIONS_READ = 'notifications_read'
export const NOTIFICATIONS_FULL_ACCESS = 'notifications_full_access'

// Ссылка на 'Система КТЗ'
export const SYSTEM_LINK_SHOW = 'system_link_show'

// Только свои ИОГВ
export const ONLY_OWN_IOGVS = 'only_own_iogvs'

class PermissionService {
  user = {}

  permissionList = {
    news: {
      create: [NEWS_FULL_ACCESS, NEWS_CREATE],
      edit: [NEWS_FULL_ACCESS, NEWS_EDIT],
      delete: [NEWS_FULL_ACCESS],
      restore: [NEWS_FULL_ACCESS]
    },
    npa: {
      create: [NPA_FULL_ACCESS, NPA_CREATE],
      edit: [NPA_FULL_ACCESS, NPA_EDIT],
      delete: [NPA_FULL_ACCESS],
      restore: [NPA_FULL_ACCESS]
    },
    plan: {
      create: [PLAN_FULL_ACCESS, PLAN_CREATE],
      edit: [PLAN_FULL_ACCESS, PLAN_EDIT],
      delete: [PLAN_FULL_ACCESS],
      restore: [PLAN_FULL_ACCESS]
    },
    open_data: {
      create: [OPEN_DATA_FULL_ACCESS, OPEN_DATA_CREATE],
      edit: [OPEN_DATA_FULL_ACCESS, OPEN_DATA_EDIT],
      delete: [OPEN_DATA_FULL_ACCESS],
      restore: [OPEN_DATA_FULL_ACCESS]
    },
    faq: {
      create: [FAQ_FULL_ACCESS, FAQ_CREATE],
      edit: [FAQ_FULL_ACCESS, FAQ_EDIT],
      delete: [FAQ_FULL_ACCESS],
      restore: [FAQ_FULL_ACCESS]
    },
    knm_request: {
      create: [KNM_REQUEST_FULL_ACCESS, KNM_REQUEST_CREATE],
      edit: [KNM_REQUEST_FULL_ACCESS, KNM_REQUEST_EDIT],
      delete: [KNM_REQUEST_FULL_ACCESS]
    },
    element: {
      onlyOwnIogvs: [ONLY_OWN_IOGVS]
    }
  }

  getPermissionByEntityAndType (entity, type) {
    if (entity in this.permissionList) {
      return this.permissionList[entity][type]
    }
    throw new Error('unknown entity: ' + entity)
  }

  canCreate (entity) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType(entity, 'create'))
  }

  canEdit (entity) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType(entity, 'edit'))
  }

  canDelete (entity) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType(entity, 'delete'))
  }

  canRestore (entity) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType(entity, 'restore'))
  }

  elementCan (permission) {
    return hasPermission(this.user.permissions, this.getPermissionByEntityAndType('element', permission))
  }
}

const permissionService = new PermissionService()
export default permissionService
