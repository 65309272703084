import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// импортируем иначе dayjs(datepicker) не выставит "Понедельник" первым днём недели
import 'dayjs/locale/ru'
import ru from 'element-plus/lib/locale/lang/ru' // локаль глобальная

import { torisInit } from '@/utils/auth'
import './permission'
import '@/styles/index.scss'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faSignInAlt, fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import './plugins/auth/widget'
import mitt from 'mitt'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

library.add(faSignInAlt)
library.add(fas)
library.add(far)
dom.watch()
torisInit(store, router)
const emitter = mitt()
const app = createApp(App)
app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: ru })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.emitter = emitter
app.use(router).use(store).mount('#app')
