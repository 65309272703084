import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/layout/Layout'
import externalSitesLinks from '@/store/modules/externalSitesLinks'
import {
  FAQ_FULL_ACCESS,
  NEWS_FULL_ACCESS,
  NPA_FULL_ACCESS,
  OPEN_DATA_FULL_ACCESS,
  PLAN_FULL_ACCESS,
  KNM_REQUEST_CREATE,
  KNM_REQUEST_EDIT,
  KNM_REQUEST_READ,
  KNM_REQUEST_FULL_ACCESS,
  NOTIFICATIONS_READ,
  NOTIFICATIONS_FULL_ACCESS,
  SYSTEM_LINK_SHOW
} from '@/services/permissions/PermissionService'

export const constantRouterMap = [
  {
    path: '/login/:authRedirect(.*)*',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */'@/views/access/login.vue'),
    hidden: true
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '@/views/access/logout.vue'),
    hidden: true
  },
  {
    path: '/denied',
    name: 'denied',
    component: () => import(/* webpackChunkName: "denied" */ '@/views/access/denied.vue'),
    hidden: true
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404Page" */ '@/views/errorPage/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    name: '401',
    component: () => import(/* webpackChunkName: "401Page" */ '@/views/errorPage/401.vue'),
    hidden: true
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "500Page" */ '@/views/errorPage/500.vue'),
    hidden: true
  },
  /**
   * Новости доступные для чтения всем (опубликованные: published = true)
   * - список (list)
   * - просмотр (show)
   */
  {
    path: '/news',
    component: Layout,
    children: [
      {
        path: '/news',
        component: () => import('@/views/news/list'),
        name: 'newsList',
        meta: {
          title: 'Новости',
          icon: 'fas fa-circle',
          permissions: []
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperNews',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/news',
        meta: {
          title: 'Новости'
        },
        children: [
          {
            path: '/news/show/:id(\\d+)',
            component: () => import('@/views/news/show'),
            name: 'newsShow',
            hidden: true,
            meta: {
              title: 'Просмотр',
              permissions: []
            }
          }
        ]
      }
    ]
  },
  /**
   * НПА
   * - список (list)
   * - просмотр (show)
   */
  {
    path: '/npa',
    component: Layout,
    children: [
      {
        path: '/npa',
        component: () => import('@/views/npa/list'),
        name: 'npaList',
        meta: {
          title: 'НПА',
          icon: 'fas fa-circle',
          permissions: []
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperNpa',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/npa',
        meta: {
          title: 'НПА'
        },
        children: [
          {
            path: '/npa/show/:id(\\d+)',
            component: () => import('@/views/npa/show'),
            name: '/npa/show',
            hidden: true,
            meta: {
              title: 'Просмотр',
              permissions: []
            }
          }
        ]
      }
    ]
  },
  /**
   * Планы
   * - список (list)
   * - просмотр (show)
   */
  {
    path: '/plans',
    component: Layout,
    children: [
      {
        path: '/plans',
        component: () => import('@/views/plans/list'),
        name: 'plansList',
        meta: {
          title: 'Планы',
          icon: 'fas fa-circle',
          permissions: []
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperPlans',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/plans',
        meta: {
          title: 'Планы'
        },
        children: []
      }
    ]
  },
  /**
   * Открытые данные
   * - список (list)
   * - просмотр (show)
   */
  {
    path: '/open-data',
    component: Layout,
    children: [
      {
        path: '/open-data',
        component: () => import('@/views/openData/list'),
        name: 'openDataList',
        meta: {
          title: 'Открытые данные',
          icon: 'fas fa-circle',
          permissions: []
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperOpenData',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/open-data',
        meta: {
          title: 'Открытые данные'
        },
        children: [
          {
            path: '/open-data/show/:id(\\d+)',
            component: () => import('@/views/openData/show'),
            name: '/open-data/show',
            hidden: true,
            meta: {
              title: 'Просмотр',
              permissions: []
            }
          }
        ]
      }
    ]
  },
  {
    path: '/faq',
    component: Layout,
    children: [
      {
        path: '/faq',
        component: () => import('@/views/faq/list'),
        name: 'faqList',
        meta: {
          title: 'База знаний',
          icon: 'fas fa-circle',
          permissions: []
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperFaq',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/faq',
        meta: {
          title: 'База знаний'
        },
        children: [
          {
            path: '/faq/show/:id(\\d+)',
            component: () => import('@/views/faq/show'),
            name: 'faqShow',
            hidden: true,
            meta: {
              title: 'Просмотр',
              permissions: []
            }
          }
        ]
      }
    ]
  },
  /**
   * Редактирование контента
   * - Новости
   * - НПА
   * - Открытые данные
   * - База знаний
   */
  {
    path: '/content',
    name: 'content',
    component: Layout,
    redirect: '/content/news',
    alwaysShow: true,
    meta: {
      title: 'Редактирование контента',
      permissions: [
        NEWS_FULL_ACCESS,
        NPA_FULL_ACCESS,
        PLAN_FULL_ACCESS,
        OPEN_DATA_FULL_ACCESS,
        FAQ_FULL_ACCESS
      ]
    },
    children: [
      /**
       * Новости
       */
      {
        path: '/content/news',
        component: () => import('@/views/content/news/list'),
        name: 'contentNewsList',
        meta: {
          title: 'Новости',
          permissions: [NEWS_FULL_ACCESS]
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperContentNews',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/content/news',
        meta: {
          title: 'Новости'
        },
        children: [
          {
            path: '/content/news/create',
            component: () => import('@/views/content/news/edit'),
            name: '/content/news/create',
            hidden: true,
            meta: {
              title: 'Создание',
              permissions: [NEWS_FULL_ACCESS]
            }
          },
          {
            path: '/content/news/edit/:id(\\d+)',
            component: () => import('@/views/content/news/edit'),
            name: 'contentNewsEdit',
            hidden: true,
            meta: {
              title: 'Редактирование',
              permissions: [NEWS_FULL_ACCESS]
            }
          }
        ]
      },
      /**
       * НПА
       */
      {
        path: '/content/npa',
        component: () => import('@/views/content/npa/list'),
        name: 'contentNpaList',
        meta: {
          title: 'НПА',
          permissions: [NPA_FULL_ACCESS]
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperContentNpa',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/content/npa',
        meta: {
          title: 'НПА'
        },
        children: [
          {
            path: '/content/npa/create',
            component: () => import('@/views/content/npa/edit'),
            name: 'contentNpaCreate',
            hidden: true,
            meta: {
              title: 'Создание',
              permissions: [NPA_FULL_ACCESS]
            }
          },
          {
            path: '/content/npa/edit/:id(\\d+)',
            component: () => import('@/views/content/npa/edit'),
            name: 'contentNpaEdit',
            hidden: true,
            meta: {
              title: 'Редактирование',
              permissions: [NPA_FULL_ACCESS]
            }
          }
        ]
      },
      /**
       * Открытые данные
       */
      {
        path: '/content/open-data',
        component: () => import('@/views/content/openData/list'),
        name: 'contentOpenDataList',
        meta: {
          title: 'Открытые данные',
          permissions: [OPEN_DATA_FULL_ACCESS]
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperContentOpenData',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/content/open-data',
        meta: {
          title: 'Открытые данные'
        },
        children: [
          {
            path: '/content/open-data/create',
            component: () => import('@/views/content/openData/edit'),
            name: 'contentOpenDataCreate',
            hidden: true,
            meta: {
              title: 'Создание',
              permissions: [OPEN_DATA_FULL_ACCESS]
            }
          },
          {
            path: '/content/open-data/edit/:id(\\d+)',
            component: () => import('@/views/content/openData/edit'),
            name: 'contentOpenDataEdit',
            hidden: true,
            meta: {
              title: 'Редактирование',
              permissions: [OPEN_DATA_FULL_ACCESS]
            }
          }
        ]
      },
      /**
       * База знаний
       */
      {
        path: '/content/faq',
        component: () => import('@/views/content/faq/list'),
        name: 'contentFaqList',
        meta: {
          title: 'База знаний',
          permissions: [FAQ_FULL_ACCESS]
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperContentFaq',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/content/faq',
        meta: {
          title: 'База знаний'
        },
        children: [
          {
            path: '/content/faq/create',
            component: () => import('@/views/content/faq/edit'),
            name: 'contentFaqCreate',
            hidden: true,
            meta: {
              title: 'Создание',
              permissions: [FAQ_FULL_ACCESS]
            }
          },
          {
            path: '/content/faq/edit/:id(\\d+)',
            component: () => import('@/views/content/faq/edit'),
            name: 'contentFaqEdit',
            hidden: true,
            meta: {
              title: 'Редактирование',
              permissions: [FAQ_FULL_ACCESS]
            }
          }
        ]
      }
    ]
  },
  /**
   * Личный кабинет
   * - уведомления
   * - запросы по КНМ
   */
  {
    path: '/personal',
    name: '/personal',
    component: Layout,
    redirect: '/personal/notifications',
    meta: {
      title: 'Личный кабинет',
      permissions: [
        NOTIFICATIONS_READ,
        NOTIFICATIONS_FULL_ACCESS,
        KNM_REQUEST_FULL_ACCESS,
        KNM_REQUEST_READ,
        KNM_REQUEST_CREATE,
        KNM_REQUEST_EDIT
      ]
    },
    children: [
      {
        path: '/personal/notifications',
        component: () => import('@/views/personal/notifications/list'),
        name: 'notificationsList',
        meta: {
          title: 'Уведомления',
          permissions: [
            NOTIFICATIONS_READ,
            NOTIFICATIONS_FULL_ACCESS
          ]
        }
      },
      {
        path: '/personal/requests',
        component: () => import('@/views/personal/requests/list'),
        name: 'requestsList',
        meta: {
          title: 'Запросы по КНМ',
          permissions: [
            KNM_REQUEST_FULL_ACCESS,
            KNM_REQUEST_READ,
            KNM_REQUEST_CREATE,
            KNM_REQUEST_EDIT
          ]
        }
      },
      {
        path: 'breadcrumbHelper',
        name: 'breadcrumbHelperPersonalRequests',
        hidden: true,
        component: () => import('@/views/Home'),
        redirect: '/personal/requests',
        meta: {
          title: 'Запросы по КНМ'
        },
        children: [
          {
            path: '/personal/requests/edit/:id(\\d+)',
            component: () => import('@/views/personal/requests/edit'),
            name: 'personalRequestsEdit',
            hidden: true,
            meta: {
              title: 'Просмотр',
              permissions: []
            }
          }
        ]
      }
    ]
  },
  {
    path: '/system_external',
    name: 'system_external',
    beforeEnter () { location.href = externalSitesLinks.state.SYSTEM_DOMAIN },
    meta: {
      title: '<span class="system-link">Система КТЗ</span>',
      iconName: 'Link',
      menuContainerClasses: 'lh-1_3',
      permissions: [
        SYSTEM_LINK_SHOW
      ]
    },
    children: []
  },
  {
    path: '',
    redirect: '/news',
    hidden: true
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    hidden: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRouterMap
})

export default router
