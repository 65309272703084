import { hasPermission } from '@/store/modules/permission'
import { getToken, removeToken } from '@/utils/auth'
import permissionService from '@/services/permissions/PermissionService'

const SettingsKey = 'user-settings'

const user = {
  namespaced: true,

  state: {
    token: getToken(),
    id: null,
    profile: {
      fio: ''
    },
    settings: {},
    roles: [],
    permissions: [],
    isPanelInit: false,
    panelInitError: false,
    userOrganization: null
  },

  getters: {
    hasRole: state => roles => hasPermission(state.roles, roles),
    hasPermission: state => permissions => hasPermission(state.permissions, permissions)
  },

  mutations: {
    SET_PROFILE: (state, profile) => {
      state.profile = profile
      // state.username = profile.USER_FIO ?? profile.USER_ESOV_LOGIN ?? 'Не задано'
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_PANEL_STATE: (state, bool) => {
      state.isPanelInit = bool
    },
    SET_PANEL_INIT_ERROR: (state, bool) => {
      state.panelInitError = bool
    },
    SET_USER_ORGANIZATION: (state, value) => {
      state.userOrganization = value
    }
  },

  actions: {
    GetUserInfo ({ commit, state }, href = '/api/auth/access/info') {
      commit('SET_TOKEN', getToken())
      return fetch(href, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      }).then(response => response.json())
        .then(data => {
          if (!data) {
            throw new Error('request doesn\'t return data')
          }
          if (data.roles && data.roles.length > 0) {
            commit('SET_ROLES', data.roles)
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_ID', data.id)
            commit('SET_USER_ORGANIZATION', data.organization)
            commit('SET_PROFILE', {
              fio: data.fio
            })
            permissionService.user = data
          } else {
            throw new Error('getInfo: roles must be a non-null array !')
          }
          return data
        })
    },

    FedLogout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', undefined)
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resolve()
      })
    },
    UpdateSettings ({ state }) {
      window.localStorage.setItem(SettingsKey, JSON.stringify(state.settings))
    },
    panelInit ({ commit }) {
      commit('SET_PANEL_STATE', true)
    },
    panelError ({ commit }) {
      commit('SET_PANEL_INIT_ERROR', true)
    }
  }
}

export default user
